<template>
    <!-- menu -->
    <div class="flex flex-wrap align-items-center justify-content-center p-fluid">
        <div class="col-12 md:col-5">
            <div v-if="hash == null" class="card">
                <div class="mb-4 mt-6" style="text-align: center">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                </div>
            </div>
            <div v-if="hash != null" class="w-full absolute top-0 left-0">
                <Toast />

                <!-- title bar -->
                <div
                    class="z-5 fixed top-0 left-0 w-full flex align-items-center justify-content-between flex-wrap card-container green-container bg-white purpply-topbar p-0">
                    <img class="my-0 ml-2 flex align-items-center justify-content-center" src="images/logo-anh.png"
                        style="height: 30px" />
                    <div class="inline-block flex align-items-center justify-content-center text-black">
                        店家: {{ this.shopNameValue }}
                    </div>
                    <div v-on:click="copyLink"
                        class="inline-block flex align-items-center justify-content-center purpply-share-button w-7rem h-3rem">
                        分享連結
                    </div>
                </div>
                <!-- dummy bar to reserve space-->
                <div class="top-0 left-0 h-3rem">&nbsp;</div>
                <!-- 左側分類 -->
                <div class="fixed purpply-inline-block" style="width: 30%">
                    <div v-on:click="selectCategory(category.id)" class="pt-2 pl-3 pb-2 w-full" v-for="category in menu"
                        v-bind:key="category.id" v-bind:class="{ selected_category: selectedCategory == category.id }"
                        style="cursor:pointer;">
                        {{ category.name }}
                    </div>
                </div>
                <!-- 右側品項 -->
                <div class="absolute bg-white purpply-inline-block" style="left: 30%; width: 70%">
                    <div class="pt-2 pl-3 pr-3 pb-2 w-full bg-white" v-for="category in menu" v-bind:key="category.id"
                        :ref="'category' + category.id">
                        <div class="text-lg font-bold border-bottom-2 p-1">{{ category.name }}</div>
                        <div v-on:click="selectItem(item)" v-for="item in category.items" v-bind:key="item.name"
                            style="cursor:pointer;">
                            <div class="w-9 inline-block p-2">{{ item.name }}</div>
                            <div class="w-3 inline-block p-2 text-right" style="color: #ff5339">
                                $ {{ item.price }}
                            </div>
                            <div class="pl-2 text-sm text-gray-400 w-full">{{ item.desc }}</div>
                        </div>
                    </div>
                    <div class="h-30rem">&nbsp;</div>
                </div>
            </div>
            <div v-if="hash != null" class="w-full fixed bottom-0 left-0">
                <!-- footer bar -->
                <div
                    class="w-full flex align-items-center justify-content-between flex-wrap card-container green-container bg-gray-800 purpply-bottombar p-0">
                    <div
                        class="border-white border-round border-1 inline-block flex align-items-center justify-content-center text-sm h-2rem ml-3 py-2 px-2 text-white">
                        <!--<i class="flex align-items-center justify-content-center pi pi-shopping-cart text-white text-lg h-4rem">&nbsp;{{ summarizeCart }}</i> -->
                        {{ summarizeCart }}
                    </div>
                    <div v-on:click="showCart"
                        class="inline-block flex align-items-center justify-content-center p-button-purpply p-button w-10rem h-4rem m-2 p-2">
                        查看已選商品
                    </div>
                </div>
            </div>

            <!-- 點選品項 -->
            <Dialog position="bottom" v-model:header="selectedItemName" v-model:visible="displaySelectionDialog"
                closable="false" dismissableMask="true" :breakpoints="{ '960px': '96vw' }" :style="{ width: '30vw' }"
                :modal="true">

                <div class="grid formgrid">
                    <!-- 姓名 -->
                    <span class="p-input-icon-left p-float-label mt-4 mb-2 col-12">
                        <i class="pi pi-user ml-2" />
                        <InputText class="w-full" type="text" id="name" v-model="nameValue"
                            v-bind:class="{ 'p-invalid': isInvalidName }" />
                        <label for="name">訂購人名 (必填，可填寫暱稱)</label>
                    </span>
                    <!-- 選項 -->
                    <div v-for="(itemValue, itemKey) in selectedItem['options']" :key="itemKey" class="mt-2 col-12">
                        <div class="mx-2 my-2 text-lg">
                            <span class="text-pink-300">▸&nbsp;</span>
                            <span class="font-bold">{{ itemValue.name }}</span>
                            <span class="text-pink-300">&nbsp;◂</span>
                        </div>
                        <div class="mt-2 w-full">
                            <div v-bind:class="{ purpply_option_extra_selected: initOptionSelected(id, itemValue.name, option), 'mr-2': id % 3 != 2 }"
                                v-on:click="selectOption($event, itemValue.name, option)"
                                class="my-2 inline-block border-round text-lg text-center py-2 px-2 purpply_options_extras cursor-pointer"
                                style="width:31.5%;" v-for="(option, id) in itemValue['elem']" :key="option">
                                {{ option }}
                            </div>
                        </div>
                    </div>
                    <!-- 加點 -->
                    <div v-for="(itemValue, itemKey) in selectedItem['extras']" :key="itemKey" class="mt-2 col-12">
                        <div class="mx-2 my-2 text-lg">
                            <span class="text-pink-300">▸&nbsp;</span>
                            <span class="font-bold">{{ itemValue.name }}</span>
                            <span class="text-pink-300">&nbsp;◂</span>
                        </div>
                        <div class="mt-2 w-full">
                            <div v-bind:class="{ purpply_option_extra_selected: initExtraSelected(itemValue.name, extra['name']), 'mr-2': id % 3 != 2 }"
                                v-on:click="selectExtra($event, itemValue.name, extra['name'], extra['price'])"
                                class="my-2 inline-block border-round text-lg text-center py-2 px-2 purpply_options_extras cursor-pointer"
                                style="width:31.5%;" v-for="(extra, id) in itemValue['elem']" :key="extra">
                                {{ extra['name'] }} <br /> ${{ extra['price'] }}
                            </div>
                        </div>
                    </div>
                    <!-- 數量 -->
                    <div class="mx-2 my-2 text-lg col-12">
                        <span class="text-pink-300">▸&nbsp;</span>
                        <span class="font-bold">數量</span>
                        <span class="text-pink-300">&nbsp;◂</span>
                    </div>
                    <span class="p-float-label mt-2 mb-2 col-12">
                        <Dropdown id="dropdown" class="w-full" :options="quantities" optionValue="code" optionLabel="name"
                            v-model="selectedQuantity"></Dropdown>
                    </span>
                    <!-- 確認 -->
                    <div class="col-12 mb-2 mt-4">
                        <Button v-if="editingItemIndex < 0" v-on:click="applyToCart" label="加入已選商品"
                            class="p-button-secondary p-button w-full" />
                        <Button v-else v-on:click="applyToCart" label="更新已選商品" class="p-button-secondary p-button w-full" />
                    </div>
                </div>
                <template #footer> </template>
            </Dialog>
            <!-- 顯示錯誤 -->
            <Dialog position="bottom" header="請修正輸入資料" v-model:visible="displayInvalidPopup" closable="false"
                dismissableMask="true" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                <p class="line-height-3 m-0">
                <ul id="example-1" class="p-1 m-0">
                    <div v-for="invalid in invalidSummary" :key="invalid" class="mt-2">
                        - {{ invalid }}
                    </div>
                </ul>
                </p>
                <template #footer>
                </template>
            </Dialog>

            <!-- 購物車 -->
            <Dialog position="bottom" header="已選商品紀錄" v-model:visible="displayCartDialog" closable="false"
                dismissableMask="true" :breakpoints="{ '960px': '96vw' }" :style="{ width: '30vw' }" :modal="true">
                <div class="grid formgrid col-12">
                    <div v-if="cart.length == 0">目前沒有選擇任何商品喔</div>
                    <div class="col-12 p-2" v-for="(item, id) in cart" :key="id">
                        <div class="inline-block col-2">
                            <div class="flex align-items-center justify-content-center bg-gray-100 border-round"
                                style="width:2.5rem;height:2.5rem">
                                <i class="pi text-gray-500 text-sm">{{ item.quantity }}x</i>
                            </div>
                        </div>
                        <div class="inline-block col-4 text-lg font-bold">{{ item.name }}</div>
                        <div class="inline-block col-2 text-lg">${{ (item.basePrice + item.extrasTotal) * item.quantity }}
                        </div>
                        <div class="inline-block col-2">
                            <div v-on:click="editCartItem(id)"
                                class="flex align-items-center justify-content-center bg-blue-100 border-round cursor-pointer"
                                style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-pencil text-blue-500 text-sm"></i>
                            </div>
                        </div>
                        <div class="inline-block col-2">
                            <div v-on:click="removeCartItem(id)"
                                class="flex align-items-center justify-content-center bg-blue-100 border-round cursor-pointer"
                                style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-trash text-blue-500 text-sm"></i>
                            </div>
                        </div>
                        <div class="inline-block col-2">&nbsp;</div>
                        <div class="inline-block col-10">
                            <div class="inline-block text-gray-600 mt-1 mr-2" v-for="key in item.options" :key="key">{{ key
                            }}</div>
                            <div class="inline-block text-gray-600 mt-1 mr-2" v-for="key in item.extras" :key="key">{{ key
                            }}</div>
                            <div class="inline-block text-gray-600 font-bold mt-1" v-if="item.buyer != ''"> {{ item.buyer }}
                            </div>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <!-- 確認 -->
                    <div class="w-full mb-2 mt-4">
                        <Button v-if="this.cart.length != 0" v-on:click="submitCart" label="送出"
                            class="p-button-secondary p-button w-full" />
                        <Button v-else v-on:click="submitCart" label="跳過此步驟" class="p-button-secondary p-button w-full" />
                    </div>
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { useRoute } from "vue-router";

export default {
    data() {
        return {
            hash: null,
            b: null,
            shopNameValue: "",
            menu: null,
            nameValue: "",
            selectedCategory: -1,
            selectedItemID: -1,
            selectedItemName: "",
            selectedItemPrice: -1,
            selectedItem: null,
            selectedOptions: {},
            selectedExtras: {},
            selectedExtrasPrice: {},
            selectedQuantity: '1',
            editingItemIndex: -1,
            cart: [],
            displaySelectionDialog: false,
            quantities: [
                { name: '1', code: '1' },
                { name: '2', code: '2' },
                { name: '3', code: '3' },
                { name: '4', code: '4' },
                { name: '5', code: '5' },
                { name: '6', code: '6' },
                { name: '7', code: '7' },
                { name: '8', code: '8' },
                { name: '9', code: '9' },
                { name: '10', code: '10' },
                { name: '11', code: '11' },
                { name: '12', code: '12' },
                { name: '13', code: '13' },
                { name: '14', code: '14' },
                { name: '15', code: '15' },
                { name: '16', code: '16' },
                { name: '17', code: '17' },
                { name: '18', code: '18' },
                { name: '19', code: '19' },
            ],
            invalidSummary: [],
            displayInvalidPopup: false,
            displayCartDialog: false,
            flagInit: false,
        };
    },
    mounted() {
        this.fetchInfo();
    },
    beforeUnmount() { },
    created() { },
    methods: {
        fetchInfo() {
            const route = useRoute();
            const h = route.query.h;
            const selected_shop = route.params.selected_shop;
            const channel = route.params.channel;
            const name = route.params.name;
            const address = route.params.address;
            const phone = route.params.phone;
            const company_id = route.params.company_id;
            const note = route.params.note;
            const request_date = route.params.request_date;
            const request_time = route.params.request_time;

            this.hash = h;

            var params = {
                h: h,
                selected_shop: selected_shop,
                channel: channel,
                name: name,
                address: address,
                phone: phone,
                company_id: company_id,
                note: note,
                request_date: request_date,
                request_time: request_time,
            };

            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/v", {
                method: "GET",
                withCredentials: true,
                params: params,
            })
                .then((response) => {
                    console.log(response.data);
                    this.statusLogin = response.data["code"];
                    if (this.statusLogin == 200) {
                        this.shopNameValue = response.data["shop_name"];
                        this.menu = response.data["menu"];
                        this.nameValue = response.data["name"];
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        copyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.style.position = "fixed";
            textArea.style.top = 0;
            textArea.style.left = 0;

            textArea.style.width = "2em";
            textArea.style.height = "2em";

            textArea.style.padding = 0;

            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";

            textArea.style.background = "transparent";

            textArea.value = text;

            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
        },
        copyLink() {
            this.copyTextToClipboard(location.href);
            alert("複製完成！");
        },
        selectCategory(category_id) {
            this.selectedCategory = category_id;
            var element = this.$refs["category" + category_id];
            console.log(element);
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        },
        selectItem(item) {
            this.selectedItemID = item.d_id;
            this.selectedItemName = item.name;
            this.selectedItem = item;
            this.selectedItemPrice = item.price;
            this.selectedOptions = {};
            this.selectedExtras = {};
            this.selectedExtrasPrice = {};
            this.selectedQuantity = '1';
            this.flagInitDialog = 'defaultOptionExtra';
            this.editingItemIndex = -1; // -1 for new item
            this.displaySelectionDialog = true;
        },
        selectOption(event, group, option) {
            this.flagInitDialog = 'loadOptionExtra';
            this.selectedOptions[group] = option;
            // this is a hack, should be modified later
            // loop through all child of parent
            var parent = event.currentTarget.parentElement;
            for (var child = parent.firstChild; child !== null; child = child.nextSibling) {
                console.log(child);
                if (child.classList == null) continue;
                if (child.innerText == option) {
                    child.classList.add("purpply_option_extra_selected");
                } else {
                    child.classList.remove("purpply_option_extra_selected");
                }
            }
        },
        initOptionSelected(id, group, option) {
            if (this.flagInitDialog == 'defaultOptionExtra') {
                if (id == 0) {
                    this.selectedOptions[group] = option;
                    return true;
                } else {
                    return false;
                }
            } else if (this.flagInitDialog == 'loadOptionExtra') {
                if (group in this.selectedOptions && option == this.selectedOptions[group])
                    return true;
                return false;
            }
        },
        initExtraSelected(group, extra) {
            if (this.flagInitDialog == 'defaultOptionExtra') {
                return false;
            } else if (this.flagInitDialog == 'loadOptionExtra') {
                if (group in this.selectedExtras && extra == this.selectedExtras[group])
                    return true;
                return false;
            }
        },
        selectExtra(event, group, extra, price) {
            var doSelect = true;
            if (this.selectedExtras[group] == extra) doSelect = false;
            // this is a hack, should be modified later
            // loop through all child of parent
            var parent = event.currentTarget.parentElement;
            for (var child = parent.firstChild; child !== null; child = child.nextSibling) {
                console.log(child);
                if (child.classList == null) continue;
                console.log(child.innerText.split('\n')[0]);
                if (child.innerText.split('\n')[0].split('\n')[0].trim() == extra && doSelect) { // this is a hack in a hack, git rid of the price
                    child.classList.add("purpply_option_extra_selected");
                    this.selectedExtras[group] = extra;
                    this.selectedExtrasPrice[group] = price;
                    this.$toast.add({ severity: 'info', summary: '已選加點', detail: '再次點擊即可取消', life: 3000 });
                } else {
                    child.classList.remove("purpply_option_extra_selected");
                    if (!doSelect) {
                        delete this.selectedExtras[group];
                        delete this.selectedExtrasPrice[group];
                    }
                }
            }
        },
        applyToCart() {
            // check
            var wrongFmt = false;
            this.invalidSummary = [];
            if (parseInt(this.selectedQuantity) < 1 || parseInt(this.selectedQuantity) > 19) {
                wrongFmt = true;
                this.invalidSummary.push("請選擇數量");
            }

            this.nameValue = this.nameValue.trim();
            if (this.nameValue.length > 20) {
                wrongFmt = true;
                this.invalidSummary.push("訂購人名太長");
            }
            if (this.nameValue.length == 0) {
                wrongFmt = true;
                this.invalidSummary.push("訂購人名不得為空白");
            }

            // 熱飲
            var hot = false;
            for (key in this.selectedOptions)
                if (this.selectedOptions[key].includes('熱')) hot = true;

            if (hot)
                for (key in this.selectedExtras) {
                    if (!this.selectedExtras[key].includes('波霸')) {
                        wrongFmt = true;
                        this.invalidSummary.push("熱飲加點僅能加波霸");
                    }
                }

            console.log(hot);

            if (wrongFmt) {
                this.displayInvalidPopup = true;
                return;
            }

            // build item
            var item = {}
            item['id'] = this.selectedItemID;
            item['name'] = this.selectedItemName;
            item['buyer'] = this.nameValue;
            item['basePrice'] = this.selectedItemPrice;
            item['options'] = {};
            item['extras'] = {};
            item['extrasPrice'] = {};
            for (var key in this.selectedOptions) {
                item['options'][key] = this.selectedOptions[key];
            }
            for (key in this.selectedExtras) {
                item['extras'][key] = this.selectedExtras[key];
            }
            var extrasTotal = 0;
            for (key in this.selectedExtras) {
                item['extrasPrice'][key] = this.selectedExtrasPrice[key];
                extrasTotal += this.selectedExtrasPrice[key];
            }
            item['extrasTotal'] = extrasTotal;
            item['quantity'] = parseInt(this.selectedQuantity);

            item['item'] = this.selectedItem; // add a reference
            console.log(item);


            if (this.editingItemIndex >= 0) {
                // refresh cart
                this.cart[this.editingItemIndex] = JSON.parse(JSON.stringify(item));
                this.$toast.add({ severity: 'success', summary: '已選商品紀錄', detail: '品項已加入已選商品紀錄', life: 3000 });
            } else {
                // add to cart by cloning first
                this.cart.push(JSON.parse(JSON.stringify(item)));
                this.$toast.add({ severity: 'success', summary: '已選商品紀錄', detail: '品項已加入已選商品紀錄', life: 3000 });
            }
            this.displaySelectionDialog = false;
        },
        editCartItem(id) {
            var item = JSON.parse(JSON.stringify(this.cart[id]));

            // reverse item
            this.selectedItemID = item.id;
            this.selectedItemName = item.name;
            this.nameValue = item.buyer;
            this.selectedItem = item['item'];
            this.selectedItemPrice = item['basePrice'];
            this.selectedOptions = item['options'];
            this.selectedExtras = item['extras'];
            this.selectedExtrasPrice = item['extrasPrice'];
            this.selectedQuantity = item['quantity'].toString();

            this.editingItemIndex = id;
            this.flagInitDialog = 'loadOptionExtra'
            this.displaySelectionDialog = true;
        },
        removeCartItem(id) {
            this.cart.splice(id, 1);
            this.$toast.add({ severity: 'success', summary: '已選商品紀錄', detail: '品項已從已選商品紀錄移除', life: 3000 });
        },
        showCart() {
            this.displayCartDialog = true;
        },
        buildOrderJSON(item) {
            console.log(item);
            var output = {};

            var options = "";
            var extras = "";
            for (var key in item['options']) {
                options += item['options'][key] + " ";
            }
            for (key in item['extras']) {
                extras += item['extras'][key] + " ";
            }

            var option_extra = (options.trim() + " " + extras.trim()).trim();
            if (option_extra != '') option_extra = " (" + option_extra + ")";

            output['price'] = (item['basePrice'] + item['extrasTotal']) * item['quantity'];
            output['text'] = item['name'] + option_extra;
            output['quantity'] = item['quantity'];
            return output;
        },
        redirectToStatusPage() {
            this.$router.push({
                path: "/status",
                name: "status",
                query: {
                    hash: this.hash,
                },
            });
        },
        submitCart() {
            // check
            // var wrongFmt = false;
            // this.invalidSummary = [];
            // if(this.cart.length == 0) {
            //     wrongFmt = true;
            //     this.invalidSummary.push("目前尚無已選商品，請先選擇商品")
            // }

            // if(wrongFmt) {
            //     this.displayInvalidPopup = true;
            //     return;
            // }
            if (this.cart.length == 0) {
                this.redirectToStatusPage();
            }

            var output = {};
            for (var idx in this.cart) {
                var buyer = this.cart[idx]['buyer'];
                if (this.cart[idx]['buyer'] in output) {
                    output[buyer].push(this.buildOrderJSON(this.cart[idx]));
                } else {
                    output[buyer] = [this.buildOrderJSON(this.cart[idx])];
                }
            }

            var order = [];
            for (buyer in output) {
                order.push({ 'nick': buyer, 'order': output[buyer] });
            }
            console.log(order);

            // {"nick":"連先生","order":[{"price":30,"text":"招牌紅+(●熱+正常甜)","quantity":1},{"price":35,"text":"炭焙烏龍+(●熱+正常甜)","quantity":1},{"price":35,"text":"手採高山青+(●熱+正常甜)","quantity":1}]}
            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/dv", {
                method: "POST",
                withCredentials: true,
                data: new URLSearchParams({
                    h: this.hash,
                    order_content: JSON.stringify(order),
                    d: [],
                }),
            })
                .then((response) => {
                    console.log(response.data);
                    if (response.data["code"] == 200) {
                        this.redirectToStatusPage();
                    } else {
                        this.$toast.add({ severity: 'error', summary: '已選商品紀錄', detail: response.data["msg"], life: 3000 });
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
    },
    computed: {
        summarizeCart() {
            var quantity = 0;
            var total = 0;
            for (var i = 0; i < this.cart.length; i++) {
                var item = this.cart[i];

                // calculate extra
                var extraPrice = 0;
                for (var key in item['extrasPrice'])
                    extraPrice += item['extrasPrice'][key];

                var unitPrice = item['basePrice'] + extraPrice;
                console.log(unitPrice);

                total += unitPrice * item['quantity'];
                if (unitPrice > 3) quantity += item['quantity'];
            }
            console.log(total);
            return quantity;
        },
    },
};
</script>
<style scoped lang="scss">
* {
    scroll-margin-top: 3rem;
}

.selected_category {
    background-color: white;
}

.purpply_option_extra_selected {
    background-color: var(--purpply-primary-color);
    color: white;
}

.purpply_options_extras {
    border: 1px solid var(--purpply-primary-color);
}

.purpply-inline-block {
    vertical-align: top;
    display: inline-block;
}

.purpply-topbar {
    border-top: 1px solid var(--purpply-primary-color);
    border-bottom: 1px solid var(--purpply-primary-color);
}

.purpply-bottombar {
    border-top: 1px solid var(--purpply-primary-color);
    border-bottom: 1px solid var(--purpply-primary-color);
}

.purpply-share-button {
    border: 0px;
    background-color: var(--purpply-primary-color);
    color: white;
}

.btn {
    border-radius: 10px;
    border-bottom: 0px;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
    display: none;

    &+label {
        cursor: pointer;
        min-width: 120px;
        background-color: var(--purpply-unselected-color);

        &:hover {
            background: none;
            color: var(--purpply-primary-color);
        }

        &:after {
            background-color: var(--purpply-primary-color);
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }

    &.toggle-left+label {
        border-right: 0;

        &:after {
            left: 100%;
        }
    }

    &.toggle-right+label {
        margin-left: 0px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: #fff;
        background-color: var(--purpply-primary-color);
        transition: color 200ms;

        &:after {
            left: 0;
        }
    }
}
</style>
